import React from 'react';
import './App.css';
import { Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {reinins} from "./socioData";


class Calculate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }


    render() {

        let trios = [
             {name: "some name", reinins: [0,2,10],
             groups: [ {name: "g1", types: [0,1,2,3]},{name: "g2", types: [4,5,6,7]},
                       {name: "g3", types: [8,9,10,11]},{name: "g4", types: [12,13,14,15]} ]}
         ];
        trios = [];

        reinins.forEach((d1,i1) => reinins.forEach((d2,i2) => {

            if (i1 === i2 || trios.filter(t => (t.reinins.includes(i1) && t.reinins.includes(i2))).length > 0)
            {
                //skip
            }
            else
            {
                let groups = [[],[],[],[]];

                for (let i=0; i<16; i++) {
                    if (d1.value[i] === 1 && d2.value[i] === 1) groups[0].push(i);   // ++ 1kv
                    else
                    if (d1.value[i] === 0 && d2.value[i] === 0) groups[1].push(i);   // +- 2kv
                    else
                    if (d1.value[i] === 1 && d2.value[i] === 0) groups[2].push(i);   // -- 3kv
                    else
                    if (d1.value[i] === 0 && d2.value[i] === 1) groups[3].push(i);   // -+ 4kv
                }

                if (groups[0].length === 4 && groups[1].length === 4 && groups[2].length === 4 && groups[3].length === 4) {

                    reinins.forEach((d3,i3) => {

                        if (i1 !== i3 && i2 !== i3)
                            if (groups[0].filter(t => d3.value[t] === 1).length === 4 &&
                                groups[1].filter(t => d3.value[t] === 1).length === 4 &&
                                groups[2].filter(t => d3.value[t] === 0).length === 4 &&
                                groups[3].filter(t => d3.value[t] === 0).length === 4)
                            {
                                //found third d

                                trios.push({
                                    name: "", reinins:[i1,i2,i3], groups: groups.map(g => ({ name: "", types: g}))
                                });
                            }

                    });

                }
            }
        } ));

        console.log(trios);


        return <div>text</div>;
    }
}

export default Calculate;