import React from 'react';
import './App.css';
import { Button, Table } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {reinins, types, groups} from "./socioData";


class Content extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedGroups: [],
            selectedTypes: [],
            showReinins: false,
            showPairs: false,
            showNames: false
        };
    }

    componentDidMount () {

        this.setState(JSON.parse(localStorage.getItem('state')));
    };

    componentDidUpdate (prevProp, prevState, snapshot) {

        localStorage.setItem('state',JSON.stringify(this.state));
    };


    render() {

        let descriptionRender = <h5>Підбір типу за приналежністю до групи</h5>;
        let commentRender = <p className="text-secondary " style={{fontSize: 0.8+"em"}}>
            *Ще не всі групи були досконально описані та досліджені соціоніками, та деякі назви не є фінально узгодженими. Ви маєте можливість зробити свій вклад в Соціоніку, дослідити нову групу.
        </p>;

        // CLEAR FEATURE

        let clearReinins = () => {
            this.setState({selectedGroups: []})
        };
        let clearTypes = () => {
            this.setState({selectedTypes: []})
        };
        let clearGroupsButton = <Button size="sm" variant="light" className="mx-2"
                                        onClick={() => clearReinins()}>X</Button>;
        let clearTypesButton = <Button size="sm" variant="light" className="mx-2"
                                       onClick={() => clearTypes()}>X</Button>;


        // === REININS ===

        let selectGroup = (quatId, groupId) => {
            let arr = this.state.selectedGroups;
            let index = arr.findIndex(s => s.quatId === quatId);

            if (index === -1)
                arr.push({quatId: quatId, groupId: groupId});
            else {
                if (arr[index].groupId !== groupId)
                    arr.push({quatId: quatId, groupId: groupId});
                arr.splice(index, 1);
            }
            this.setState({selectedGroups: arr});
        };

        /*
        console.log("old",groups);

        let gcopy = groups.map(g=> {

            let ng = JSON.parse(JSON.stringify(g));
            ng.groups[3] = {...g.groups[2]};
            ng.groups[2] = {...g.groups[3]};

            return ng;
        });

        console.log("new",gcopy);
*/


        let normalButton = "light";
        let selectedButton = "secondary";

        let groupsListRender = <Table className="w-100" size="sm">
            <thead>
            <tr>
                <th colSpan={this.state.exes ? 3 : 2}>Малі групи {clearGroupsButton}</th>
            </tr>
            </thead>
            <tbody>{groups.map((q, idq) => {

                let groupIdRender = <span className="small text-secondary">{"#{0}".format(idq+1)}</span>;
                let groupName = <b className="mx-1">{"{0}".format(q.name === "" ? "Група" : q.name)}</b>;
                let reininsCodes = "({0})".format(q.reinins.map(r=> r+1).join(','));

                let reininListText = q.reinins.map(r=> reinins[r].name).map(s=> s.replace(' ','/'));
                reininListText = "{0} * {1} + {2}".format(reininListText[0],reininListText[1],reininListText[2]);
                let reininsList = <span className="text-secondary small">{reininListText}</span>;
                let groupsPairs = [[1,1,1], [0,0,1], [1,0,0], [0,1,0]];
                let pairsText = groupsPairs.map(p=> {

                    let d1 = reinins[q.reinins[0]];
                    let d2 = reinins[q.reinins[1]];
                    let d3 = reinins[q.reinins[2]];

                    return "{0}-{1}-{2}".format((p[0] ? d1.plus : d1.minus),(p[1] ? d2.plus : d2.minus),(p[2] ? d3.plus : d3.minus));
                }).join(" | ");

                return (
                    <tr key={idq} >
                        <td key={idq} className="pt-1">
                            <span className="small d-flex justify-content-between">
                                <span>{groupIdRender}{groupName} {this.state.showReinins ? reininsList : ""}</span>
                                <span className="text-secondary small">{this.state.showPairs ? pairsText : ""}</span>
                            </span>
                            <div className="d-flex justify-content-between pt-1" >
                            {
                                q.groups.map((g, idg) => {

                                    let isSelected = this.state.selectedGroups.findIndex(s =>
                                        (s.quatId === idq && s.groupId === idg)
                                    ) !== -1;


                                    return <div className="w-25 mx-1 d-flex flex-column" key={idg}>
                                        <Button
                                            variant={isSelected ? selectedButton : normalButton}
                                            size="sm"
                                            onClick={() => selectGroup(idq, idg)}>
                                            {g.name === "" ?
                                                <span className="small text-secondary">{g.types.map(t=> t+1).join(',')}</span>
                                                : g.name}</Button>
                                        <span className="text-secondary mx-1 align-self-center" style={{fontSize: 0.7 +"em"}}>
                                           {g.types.map((t,i) => {
                                                   let codeName = this.state.showNames ? types[t].name : types[t].code;
                                                   return this.state.selectedTypes.includes(t) ?
                                                       <b key={i} style={{fontSize: 1.0 + "em"}}><u>{codeName} </u></b> :
                                                       codeName + " ";
                                               }
                                           )}
                                        </span>
                                    </div>
                                })
                            }
                            </div>
                        </td>
                    </tr>);
            })}</tbody>
        </Table>;


        // === TYPES ===
         let highlightTypes = types.map(t =>  //each type has array of selected groups
             {
                 return this.state.selectedGroups
                     .map(s => {
                         //console.log("check selected:", s);
                         //console.log("check types", groups[s.quatId].groups[s.groupId]);
                          return   groups[s.quatId].groups[s.groupId].types.includes(t.id) ? s : null
                         }
                     ).filter(s => s !== null)
             }
         );

         let selectType = (id) => {
            let arr = this.state.selectedTypes;
            if (arr.includes(id))
                arr = [];
            else
                arr = [id];
            this.setState({selectedTypes: arr});
        };

         //console.log("selectedTypes");

         function comparePoints(a, b) {
            if (highlightTypes[a.id].length > highlightTypes[b.id].length) {
                return -1;
            }
            return 0;
        }

         let clonedArray = [...types];
         clonedArray.sort(comparePoints);

         let typesListRender = <Table className="" size="sm">
         <thead>
         {/*
             <tr>
                 <th colSpan={16}>Типи {clearTypesButton}</th>
             </tr>
         */}
         </thead>
         <tbody>
         <tr key={1} className="border-bottom border-dark">
         {
                clonedArray.map((t, idx) => {

                    let topValue = Math.max(...(highlightTypes.map(ht => ht.length)));
                    let boltTop = highlightTypes[t.id].length === topValue && topValue > 0;
                    let points = highlightTypes[t.id].length;
                    let pointText = points > 0 ? " (" + points + "/" + this.state.selectedGroups.length + ")" : "";

                    let name = <span className="d-flex flex-column">
                        <span>{t.name}</span>
                        <span style={{fontSize: 0.7+"em"}}>{t.code} {pointText}</span>
                    </span>;

                    return <td key={idx}>
                        <Button
                            variant={this.state.selectedTypes.includes(t.id) ? selectedButton : normalButton}
                            size="sm"
                            onClick={() => selectType(t.id)}>
                            {boltTop ? <b>{name}</b> : name}
                        </Button>
                        </td>
                })
            }
         </tr></tbody>
         </Table>;


        let checkmarkRender = <form className="d-flex">
            <div className="form-check">
                <input type="checkbox" checked={this.state.showReinins} className="form-check-input" id="exampleCheck1"
                       onChange={() => {  this.setState({showReinins: !this.state.showReinins}); }   }/>
                <label className="form-check-label small" htmlFor="exampleCheck1">
                    <span>Показати Ознаки Рейніна</span>
                </label>
            </div>
            <div className="form-check mx-3">
                <input type="checkbox" checked={this.state.showPairs} className="form-check-input" id="exampleCheck2"
                       onChange={() => {  this.setState({showPairs: !this.state.showPairs}); }   }/>
                <label className="form-check-label small" htmlFor="exampleCheck2">
                    <span>Показати ознаки груп</span>
                </label>
            </div>
            <div className="form-check mx-3">
                <input type="checkbox" checked={this.state.showNames} className="form-check-input" id="exampleCheck3"
                       onChange={() => {  this.setState({showNames: !this.state.showNames}); }   }/>
                <label className="form-check-label small" htmlFor="exampleCheck3">
                    <span>Показати назви типів</span>
                </label>
            </div>
        </form>;

        let pageRender = (
            <div>
                {descriptionRender}
                {commentRender}
                {checkmarkRender}
                <div className="fixed-bottom p-3 bg-white border overflow-scroll"
                     style={{overflowY: 'hidden'}}>{typesListRender}</div>
                {groupsListRender}

            </div>
        );

        return (pageRender);
    };

}

if (!String.prototype.format) {
    String.prototype.format = function() {
        var args = arguments;
        return this.replace(/{(\d+)}/g, function(match, number) {
            return typeof args[number] != 'undefined'
                ? args[number]
                : match
                ;
        });
    };
}

export default Content;
